export const query = `query getCategoryInitialData($externalId: String!) {
  appSettings(externalId: $externalId) {
    widgetSettings
  }
  catalog {
    categories {
      list {
        id
        name
        slug
        visible
        numOfProducts
        description
        media {
          url
          fullUrl
          mediaType
          width
          height
        }
      }
    }
  }
}
`;
